import React from 'react';
import { Helmet } from 'react-helmet';
import { navigate } from 'gatsby';

const Out = ({
  pageContext: { url },
}) => {
  if(typeof window !== 'undefined') {
    navigate(url);
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex,nofollow"></meta>
      </Helmet>
      <div>Przekierowanie</div>
    </>
  ); 
};

export default Out;
